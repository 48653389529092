@import '../../../../styles/mixins';

.viewing-custom-viewer-control {
}

.recent-searches {
    padding: 0.25rem;
}

.section-title {
    margin: 0 0 0.5rem;
    font-size: 1rem;
}

.recent-search-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.5rem;
    padding: 0.25rem 0;
}

.search-info {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.search-id {
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.page-label {
    margin-left: 4px;
    font-size: 0.85rem;
    color: #666;
}

.search-name {
    font-size: 0.85rem;
    font-weight: normal;
    color: #333;
}

.remove-btn {
    font-size: 1rem;
    color: #999;
    cursor: pointer;
    margin-left: 0.5rem;
}